import { global } from ":global";
import { LayoutProps } from "./_model";
import { navigate } from "wouter/use-browser-location";
import { useEffect } from "react";
export default function Layout(props: LayoutProps) {
  const $user = global.store.hooks.global.useUser();

  useEffect(() => {
    if ($user.inited) {
      if (!$user.exist) {
        navigate("/auth");
      }
    }
  }, [$user]);
  return (
    <div className="flex flex-col w-full h-full bg#p-bg overflow-hidden px-4 py-2">
      <div className="flex flex-row text-1rem w-full h-7% bg-white my-2  self-center rounded-2xl px-7 py-2 items-center justify-between">
        <span>{$user.profile?.email}</span>
        <span
          className="cursor-pointer"
          onClick={async () => {
            if ($user.inited) {
              await global.fb.auth.signOut();
            }
            navigate("/auth");
          }}
        >
          Logout
        </span>
      </div>
      <div className="flex flex-col w-full h-full overflow-x-hidden overflow-x-auto bg-white rounded-2xl px-2 py-2 space-y-4">
        {props.children}
      </div>
    </div>
  );
}
